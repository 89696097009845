import { RateActionTypes } from './constants';

export type RateActionType = {
    type:
        | RateActionTypes.API_RESPONSE_SUCCESS
        | RateActionTypes.API_RESPONSE_ERROR
        | RateActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | RateActionTypes.RESET

        | RateActionTypes.GET_RATES
        | RateActionTypes.GET_RATE

        | RateActionTypes.GET_API_PROVIDER_STATUS
        | RateActionTypes.UPDATE_API_PROVIDER_STATUS

        | RateActionTypes.GET_VARIANCES
        | RateActionTypes.UPDATE_VARIANCES

        | RateActionTypes.UPDATE_PRICE
    ;

    payload: {} | string;
};

class RateActions {

    apiResponseSuccess = (actionType: string, data: any): RateActionType => ({
        type: RateActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): RateActionType => ({
        type: RateActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): RateActionType => ({
        type: RateActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): RateActionType => ({
        type: RateActionTypes.RESET,
        payload: {},
    });

    getRates = (symbol:string): RateActionType => ({
        type: RateActionTypes.GET_RATES,
        payload: {symbol},
    });

    getRate = (id:string): RateActionType => ({
        type: RateActionTypes.GET_RATE,
        payload: {id},
    });

    getApiProviderStatus = (provider:string): RateActionType => ({
        type: RateActionTypes.GET_API_PROVIDER_STATUS,
        payload: {provider},
    });

    updateApiProviderStatus = (data:any): RateActionType => ({
        type: RateActionTypes.UPDATE_API_PROVIDER_STATUS,
        payload: {data},
    });

    getVariances = (symbol:string): RateActionType => ({
        type: RateActionTypes.GET_VARIANCES,
        payload: {symbol},
    });

    updateRateVariances = (data:any): RateActionType => ({
        type: RateActionTypes.UPDATE_VARIANCES,
        payload: {data},
    });

    updatePrice = (data:any): RateActionType => ({
        type: RateActionTypes.UPDATE_PRICE,
        payload: {data},
    });

}

const rateActions = new RateActions();
export { rateActions };
