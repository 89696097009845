import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CRActionTypes, CRErrors} from "./constants";
import {crActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type CRData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* conversionRates({ payload: { queryParams } }: CRData): SagaIterator {
    try {
        const param = {
            query:`query ConversionRates {
                conversionRates {
                    id
                    source_currency
                    destination_currency
                    conversion_rate
                    method
                    provider {
                        name
                    }
                    expire_time
                    status
                    expired_at
                    updated_at
                    buy_percent
                    buy_value
                    sell_percent
                    sell_value
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CRErrors.RESPONSE_200);

        const rCR = response.data;

        checkServerError(rCR);

        const conversionRates = pluckResponse(rCR, "conversionRates");

        yield put(crActions.apiResponseSuccess(CRActionTypes.RATES, conversionRates));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(crActions.apiResponseError(CRActionTypes.RATES, error));
        } else {
            yield put(crActions.apiResponseValidationErrors(CRActionTypes.RATES, error));
        }
    }
}

function* conversionRate({ payload: { queryParams } }: CRData): SagaIterator {
    try {
        const param = {
            query:`query ConversionRate($id: String!) {
                conversionRate(id: $id) {
                    id
                    source_currency
                    destination_currency
                    conversion_rate
                    method
                    provider {
                        slug
                        name
                    }
                    expire_time
                    status
                    expired_at
                    updated_at
                    buy_percent
                    buy_value
                    sell_percent
                    sell_value
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CRErrors.RESPONSE_200);

        const rCR = response.data;

        checkServerError(rCR);

        const conversionRate = pluckResponse(rCR, "conversionRate");

        yield put(crActions.apiResponseSuccess(CRActionTypes.RATE, conversionRate));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(crActions.apiResponseError(CRActionTypes.RATE, error));
        } else {
            yield put(crActions.apiResponseValidationErrors(CRActionTypes.RATE, error));
        }
    }
}

function* create({ payload: {data} }: CRData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateConversionRate(
                $unit: String!,
                $method: String!,
                $rate: String,
                $fixed: String,
                $expireTime: String
                $provider: String,
                $buyPercent: String
                $buyValue: String
                $sellPercent: String
                $sellValue: String
            ) {
                createConversionRate(
                    unit: $unit,
                    method: $method,
                    rate: $rate,
                    fixed: $fixed,
                    expireTime: $expireTime,
                    provider: $provider,
                    buyPercent: $buyPercent,
                    buyValue: $buyValue,
                    sellPercent: $sellPercent,
                    sellValue: $sellValue
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CRErrors.RESPONSE_200);

        const rCR = response.data;

        checkServerError(rCR);

        const createConversionRate = pluckResponse(rCR, "createConversionRate");

        yield put(crActions.apiResponseSuccess(CRActionTypes.CREATE, createConversionRate));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(crActions.apiResponseError(CRActionTypes.CREATE, error));
        } else {
            yield put(crActions.apiResponseValidationErrors(CRActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: CRData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateConversionRate(
                $id: String!,
                $unit: String!,
                $method: String!,
                $rate: String,
                $fixed: String,
                $expireTime: String
                $provider: String
                $buyPercent: String
                $buyValue: String
                $sellPercent: String
                $sellValue: String
            ) {
                updateConversionRate(
                    id: $id,
                    unit: $unit,
                    method: $method,
                    rate: $rate,
                    fixed: $fixed,
                    expireTime: $expireTime,
                    provider: $provider,
                    buyPercent: $buyPercent,
                    buyValue: $buyValue,
                    sellPercent: $sellPercent,
                    sellValue: $sellValue
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CRErrors.RESPONSE_200);

        const rCR = response.data;

        checkServerError(rCR);

        pluckResponse(rCR, "updateConversionRate");

        yield put(crActions.apiResponseSuccess(CRActionTypes.UPDATE, data.unit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(crActions.apiResponseError(CRActionTypes.UPDATE, error));
        } else {
            yield put(crActions.apiResponseValidationErrors(CRActionTypes.UPDATE, error));
        }
    }
}

function* remove({ payload: {id} }: CRData): SagaIterator {
    try {
        const param = {

            query:`mutation DeleteConversionRate(
                $id: String!
            ) {
                deleteConversionRate(
                    id: $id
                )
            }`,

            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CRErrors.RESPONSE_200);

        const rCR = response.data;

        checkServerError(rCR);

        pluckResponse(rCR, "deleteConversionRate");

        yield put(crActions.apiResponseSuccess(CRActionTypes.DELETE, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(crActions.apiResponseError(CRActionTypes.DELETE, error));
        } else {
            yield put(crActions.apiResponseValidationErrors(CRActionTypes.DELETE, error));
        }
    }
}

export function* watchConversionRates() {
    yield takeEvery(CRActionTypes.RATES, conversionRates);
}

export function* watchConversionRate() {
    yield takeEvery(CRActionTypes.RATE, conversionRate);
}

export function* watchInsert() {
    yield takeEvery(CRActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(CRActionTypes.UPDATE, update);
}

export function* watchDelete() {
    yield takeEvery(CRActionTypes.DELETE, remove);
}

function* crSaga() {
    yield all([
        fork(watchConversionRates),
        fork(watchConversionRate),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchDelete),
    ]);
}

export default crSaga;
